/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../axios';
import { request } from '../../axios';
import type { GetImageByNameParams, PublicTemplates } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getPublicTemplates = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<PublicTemplates>({ url: `/patient-api/templates`, method: 'GET', signal }, options);
};

export const getGetPublicTemplatesQueryKey = () => {
  return [`/patient-api/templates`] as const;
};

export const getGetPublicTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPublicTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicTemplates>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPublicTemplatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicTemplates>>> = ({ signal }) =>
    getPublicTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublicTemplates>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPublicTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicTemplates>>>;
export type GetPublicTemplatesQueryError = ErrorType<unknown>;

export function useGetPublicTemplates<
  TData = Awaited<ReturnType<typeof getPublicTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicTemplates>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getPublicTemplates>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPublicTemplates<
  TData = Awaited<ReturnType<typeof getPublicTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicTemplates>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPublicTemplates>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPublicTemplates<
  TData = Awaited<ReturnType<typeof getPublicTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicTemplates>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPublicTemplates<
  TData = Awaited<ReturnType<typeof getPublicTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPublicTemplates>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPublicTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getImageByName = (
  params: GetImageByNameParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<string>({ url: `/patient-api/templates/findImageByName`, method: 'GET', params, signal }, options);
};

export const getGetImageByNameQueryKey = (params: GetImageByNameParams) => {
  return [`/patient-api/templates/findImageByName`, ...(params ? [params] : [])] as const;
};

export const getGetImageByNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getImageByName>>,
  TError = ErrorType<unknown>,
>(
  params: GetImageByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImageByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetImageByNameQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImageByName>>> = ({ signal }) =>
    getImageByName(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getImageByName>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetImageByNameQueryResult = NonNullable<Awaited<ReturnType<typeof getImageByName>>>;
export type GetImageByNameQueryError = ErrorType<unknown>;

export function useGetImageByName<TData = Awaited<ReturnType<typeof getImageByName>>, TError = ErrorType<unknown>>(
  params: GetImageByNameParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImageByName>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getImageByName>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetImageByName<TData = Awaited<ReturnType<typeof getImageByName>>, TError = ErrorType<unknown>>(
  params: GetImageByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImageByName>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getImageByName>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetImageByName<TData = Awaited<ReturnType<typeof getImageByName>>, TError = ErrorType<unknown>>(
  params: GetImageByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImageByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetImageByName<TData = Awaited<ReturnType<typeof getImageByName>>, TError = ErrorType<unknown>>(
  params: GetImageByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImageByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetImageByNameQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
