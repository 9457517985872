import { AccountCircleOutlined, HomeOutlined } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  CardContent,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemContent,
  ListItemDecorator,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { HTMLAttributes, useDeferredValue, useEffect, useRef, useState } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { Doctor, Practice } from '../api/generated/model';
import { useQueryPractices } from '../api/generated/practice-resource/practice-resource.ts';
import { CardTitle } from './CardTitle.tsx';
import { FormGrid } from './FormGrid.tsx';

type PracticeWithType = Omit<Practice, 'doctors'> & { type: 'practice'; doctors: DoctorWithType[] };
type DoctorWithType = Doctor & { type: 'doctor' };
export type Institution = { practice: PracticeWithType; doctor?: DoctorWithType };

const MIN_SEARCH_TEXT_LENGTH = 3;
const NO_DOCTOR = { id: 'no-doctor' as const };

export function FindPracticeAndDoctor({
  onChange,
  onBlur,
  value,
  error,
}: {
  onChange: (value: Institution | null) => void;
  onBlur: () => void;
  value: Institution | null;
  error?: Merge<FieldError, FieldErrorsImpl<Institution>>;
}) {
  const [selectedPractice, setSelectedPractice] = useState<PracticeWithType | null>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorWithType | typeof NO_DOCTOR | null>(null);
  const [searchText, setSearchText] = useState('');
  const { practiceSearchResult, isPending, isError } = useCachedQueryPractices(searchText);
  const matchedDoctorToPractice = new Map(
    practiceSearchResult
      .flatMap((practice) =>
        practice.doctors.filter((doctor) => doctor.nameMatches).map((doctor) => ({ practice, doctor })),
      )
      .map(({ practice, doctor }) => [doctor, practice] as const),
  );
  const matchedDoctorOptions = [...matchedDoctorToPractice.keys()];
  const options = [...matchedDoctorOptions, ...practiceSearchResult];
  const extendedOptions =
    selectedPractice && !options.some((option) => option.id === selectedPractice.id)
      ? [...options, selectedPractice]
      : options;

  useEffect(() => {
    const newInstitution = getInstitution(selectedPractice, selectedDoctor);
    if (newInstitution?.practice.id !== value?.practice.id || newInstitution?.doctor?.id !== value?.doctor?.id) {
      onChange(newInstitution);
    }
  }, [value, onChange, selectedDoctor, selectedPractice]);

  return (
    <CardContent>
      <CardTitle sx={{ mb: 1.25 }}>Hausarztpraxis & Hausarzt</CardTitle>
      <FormControl error={!!error && !selectedPractice}>
        <Autocomplete
          inputValue={searchText}
          onInputChange={(_, value) => setSearchText(value)}
          value={selectedPractice}
          options={extendedOptions}
          getOptionLabel={(option) => option.name}
          placeholder="Suche nach Hausarzt, Praxis oder Ort"
          filterOptions={(options) => options}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_, item) => {
            if (!item) {
              setSelectedPractice(null);
              setSelectedDoctor(null);
              return;
            }
            if (item.type === 'practice') {
              setSelectedPractice(item);
              if (!item.zsrUnique && hasPracticeDoctorsWithSameZsr(item)) {
                setSelectedDoctor(NO_DOCTOR);
              } else {
                setSelectedDoctor(null);
              }
              return;
            }
            if (item.type === 'doctor') {
              const practice = matchedDoctorToPractice.get(item);
              if (!practice) return;
              setSelectedPractice(practice);
              setSelectedDoctor(item);
              return;
            }
          }}
          onBlur={onBlur}
          renderOption={(optionProps, option) => {
            // props here is missing key (incorrect types), see https://github.com/mui/material-ui/issues/39833
            const { key: _key, ...props } = optionProps as Omit<HTMLAttributes<HTMLLIElement>, 'color'> & {
              key: string;
            };
            return (
              <AutocompleteOption
                key={option.type === 'practice' ? option.id : `${option.id}/${matchedDoctorToPractice.get(option)?.id}`}
                {...props}>
                <ListItemDecorator>
                  {option.type === 'practice' ? (
                    <HomeOutlined key="practice" />
                  ) : (
                    <AccountCircleOutlined key="doctor" />
                  )}
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="title-sm">{option.name}</Typography>
                  <Typography level="body-sm" noWrap>
                    {option.type === 'practice'
                      ? option.addressLine2
                      : `${matchedDoctorToPractice.get(option)?.name}, ${matchedDoctorToPractice.get(option)?.addressLine2}`}
                  </Typography>
                </ListItemContent>
              </AutocompleteOption>
            );
          }}
          loading={isPending && searchText.length >= MIN_SEARCH_TEXT_LENGTH}
          loadingText="Lädt..."
          noOptionsText={
            isError ? (
              <Alert color="danger" sx={{ flex: 1 }}>
                Leider kann momentan nicht nach Praxen oder Hausärzten gesucht werden
              </Alert>
            ) : searchText.length < MIN_SEARCH_TEXT_LENGTH ? (
              'Suchen Sie nach Hausarzt, Praxis oder Ort'
            ) : (
              'Keine Ergebnisse gefunden'
            )
          }
          autoHighlight
        />
        {error && !selectedPractice && <FormHelperText>Hausarztpraxis muss ausgewählt werden</FormHelperText>}
      </FormControl>
      {selectedPractice && (
        <FormGrid sx={{ mt: 1 }}>
          <div>
            <Typography level="title-sm">Ausgewählte Praxis</Typography>
            <Typography level="title-md">{selectedPractice.name}</Typography>
            <Typography level="body-md">{selectedPractice.addressLine1}</Typography>
            <Typography level="body-md">{selectedPractice.addressLine2}</Typography>
          </div>
          <div>
            {selectedPractice.zsrUnique ? (
              <>
                <Typography level="title-sm">Hausärzte</Typography>
                {selectedPractice.doctors.map((doctor) => (
                  <Typography level="body-sm" key={doctor.id}>
                    {doctor.salutation} {doctor.name}
                  </Typography>
                ))}
              </>
            ) : (
              <FormControl error={!!error}>
                <FormLabel>Hausarzt</FormLabel>
                <Select
                  value={selectedDoctor?.id ?? selectedDoctor}
                  onChange={(_event, doctorId) => {
                    if (!doctorId) {
                      return;
                    }
                    if (doctorId === NO_DOCTOR.id) {
                      setSelectedDoctor(NO_DOCTOR);
                    } else {
                      setSelectedDoctor(selectedPractice.doctors.find((doctor) => doctor.id === doctorId) ?? null);
                    }
                  }}>
                  {hasPracticeDoctorsWithSameZsr(selectedPractice) && (
                    <Option value={NO_DOCTOR.id}>Kein spezifischer Hausarzt</Option>
                  )}
                  {selectedPractice.doctors.map((doctor) => (
                    <Option key={doctor.id} value={doctor.id}>
                      {doctor.salutation} {doctor.name}
                    </Option>
                  ))}
                </Select>
                {error && <FormHelperText>Hausarzt muss ausgewählt werden</FormHelperText>}
              </FormControl>
            )}
          </div>
        </FormGrid>
      )}
    </CardContent>
  );
}

function useCachedQueryPractices(searchText: string) {
  const deferredSearchText = useDeferredValue(searchText);
  const cachedPractices = useRef<PracticeWithType[]>([]);
  const enabled = deferredSearchText.length >= MIN_SEARCH_TEXT_LENGTH;
  const {
    data: practiceSearchResult = [],
    isSuccess,
    isPending,
    isError,
  } = useQueryPractices(
    { query: deferredSearchText },
    {
      query: {
        enabled,
        select: (practices) =>
          practices
            .map((practice) => ({ type: 'practice' as const, ...practice }))
            .map((practice) => ({
              ...practice,
              doctors: practice.doctors.map((doctor) => ({ type: 'doctor' as const, ...doctor })),
            })),
        retry: false,
      },
    },
  );
  if (isSuccess && practiceSearchResult !== cachedPractices.current) {
    cachedPractices.current = practiceSearchResult;
  }
  if ((!enabled || isError) && cachedPractices.current.length) {
    cachedPractices.current = [];
  }

  return {
    isError: isError && enabled,
    isPending: isPending && enabled,
    practiceSearchResult: cachedPractices.current,
  };
}

function getInstitution(
  practice: PracticeWithType | null,
  doctor: DoctorWithType | typeof NO_DOCTOR | null,
): Institution | null {
  if (!practice) {
    return null;
  }
  if (practice.zsrUnique || isDoctorNoDoctor(doctor)) {
    return { practice };
  }
  if (!doctor) {
    return null;
  }
  return { practice, doctor };
}

function isDoctorNoDoctor(doctor: DoctorWithType | typeof NO_DOCTOR | null): doctor is typeof NO_DOCTOR {
  return doctor?.id === NO_DOCTOR.id;
}

function hasPracticeDoctorsWithSameZsr(practice: PracticeWithType) {
  return practice.doctors.some((doctor) => doctor.zsr === practice.zsr);
}
