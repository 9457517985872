import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  styled,
  Textarea,
  Typography,
} from '@mui/joy';
import isEmail from 'is-email';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FileDetailsDto } from '../api/generated/model';
import { useGetPublicTemplates } from '../api/generated/public-template-resource/public-template-resource.ts';
import { useRegisterPatient } from '../api/generated/registration-resource/registration-resource.ts';
import { CardTitle } from '../components/CardTitle.tsx';
import { ContentSection } from '../components/ContentSection.tsx';
import { DateInput } from '../components/DateInput.tsx';
import { FileUpload } from '../components/FileUpload.tsx';
import { FindPracticeAndDoctor, Institution } from '../components/FindPracticeAndDoctor.tsx';
import { FormGrid, WideFormControlContainer } from '../components/FormGrid.tsx';
import { argomedData } from '../utils/argomedData.ts';

const MIN_BIRTHDATE = '1900-01-01';

export function Register() {
  const templates = useGetPublicTemplates();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    institution: Institution;
    firstname: string;
    lastname: string;
    birthdate: string;
    phone: string;
    email: string;
    comment: string;
    files: FileDetailsDto[];
    acceptsPrivacyStatement: boolean;
  }>();

  const registerMutation = useRegisterPatient();
  useEffect(() => {
    if (registerMutation.isSuccess) {
      void navigate(`/registration/${registerMutation.data.id}/verify-email`);
    }
  }, [registerMutation.isSuccess, registerMutation.data, navigate]);

  return (
    <ContentSection>
      <Typography sx={{ mb: 2 }}>{templates.data?.registrationFormIntro}</Typography>
      <form
        noValidate
        onSubmit={handleSubmit((data) => {
          if (registerMutation.isSuccess || registerMutation.isPending) return;
          registerMutation.mutate({
            data: {
              practiceInstitutionId: data.institution.practice.id,
              practiceZsr: data.institution.practice.zsr,
              ...(data.institution.doctor && {
                doctorInstitutionId: data.institution.doctor.id,
                doctorZsr: data.institution.doctor.zsr,
              }),
              firstname: data.firstname,
              lastname: data.lastname,
              birthdate: data.birthdate,
              email: data.email,
              phone: data.phone,
              files: data.files,
              comment: data.comment,
            },
          });
        })}>
        <Card size="md" sx={{ my: 1, background: 'white', gap: 2 }}>
          <Controller
            name="institution"
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value },
              formState: {
                errors: { institution: error },
              },
            }) => <FindPracticeAndDoctor onChange={onChange} onBlur={onBlur} value={value} error={error} />}
          />
          <Divider />
          <CardContent>
            <CardTitle sx={{ mb: 1 }}>Persönliche Informationen</CardTitle>
            <FormGrid>
              <FormControl error={!!errors.firstname}>
                <FormLabel>
                  <Typography>Vorname *</Typography>
                </FormLabel>
                <Input {...register('firstname', { required: true })} />
                {errors.firstname && <FormHelperText>Vorname muss ausgefüllt werden</FormHelperText>}
              </FormControl>
              <FormControl error={!!errors.lastname}>
                <FormLabel>
                  <Typography>Nachname *</Typography>
                </FormLabel>
                <Input {...register('lastname', { required: true })} />
                {errors.lastname && <FormHelperText>Nachname muss ausgefüllt werden</FormHelperText>}
              </FormControl>
              <FormControl error={!!errors.birthdate}>
                <FormLabel>
                  <Typography>Geburtsdatum *</Typography>
                </FormLabel>
                <DateInput
                  slotProps={{ input: { min: MIN_BIRTHDATE, max: new Date().toISOString().slice(0, 10) } }}
                  {...register('birthdate', {
                    required: true,
                    min: MIN_BIRTHDATE,
                    max: new Date().toISOString().slice(0, 10),
                  })}
                />
                {errors.birthdate && (
                  <FormHelperText>
                    {errors.birthdate.type == 'required'
                      ? 'Geburtsdatum muss ausgefüllt werden'
                      : errors.birthdate.type == 'max'
                        ? 'Geburtsdatum darf nicht in der Zukunft liegen'
                        : 'Geburtsdatum darf nicht vor dem 01.01.1900 liegen'}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl error={!!errors.email}>
                <FormLabel>
                  <Typography>E-Mail *</Typography>
                </FormLabel>
                <Input
                  type="email"
                  placeholder="max.muster@example.com"
                  {...register('email', { required: true, validate: (email) => isEmail(email) })}
                />
                {errors.email && (
                  <FormHelperText>
                    {errors.email.type === 'required' ? 'E-Mail muss ausgefüllt werden' : 'Ungültiges E-Mail-Format'}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>
                  <Typography>Tel. Nr.</Typography>
                </FormLabel>
                <Input placeholder={'+41 79 123 45 67'} {...register('phone')} />
              </FormControl>
              <WideFormControlContainer>
                <FormControl>
                  <FormLabel>
                    <div>
                      <Typography>Versicherungspolice</Typography>
                      <Typography fontSize={'small'} sx={{ fontWeight: 400 }}>
                        Informationen zur Versicherungspolice haben Sie bei der Anmeldung von Ihrer Krankenversicherung
                        erhalten oder finden Sie im App Ihres Krankenversicherers.
                      </Typography>
                    </div>
                  </FormLabel>
                  <Controller
                    control={control}
                    name={'files'}
                    rules={{ required: false }}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => (
                      <FileUpload
                        uploadedFiles={value}
                        onChange={(files: FileDetailsDto[]) => {
                          onChange(files);
                        }}
                      />
                    )}
                  />
                  <Typography fontSize={'small'} sx={{ mt: 0.5, fontWeight: 400 }}>
                    Unterstützte Formate: PDF, PNG, JPEG, HEIC
                  </Typography>
                </FormControl>
              </WideFormControlContainer>
              <WideFormControlContainer>
                <FormControl>
                  <FormLabel>
                    <Typography>Kommentar</Typography>
                  </FormLabel>
                  <Textarea minRows={2} {...register('comment')} />
                </FormControl>
              </WideFormControlContainer>
              <WideFormControlContainer>
                {' '}
                <FormControl error={!!errors.acceptsPrivacyStatement}>
                  <Checkbox
                    {...register('acceptsPrivacyStatement', { required: true })}
                    label="Ich habe die Datenschutzerklärung gelesen und stimme dieser zu"
                  />
                  {errors.acceptsPrivacyStatement && (
                    <FormHelperText>Bitte akzeptieren Sie die Datenschutzerklärung</FormHelperText>
                  )}
                  <FormHelperText>
                    <Typography level="body-sm" textColor="neutral.700">
                      Für diese Registrierung gilt die{' '}
                      <HelperTextLink href={argomedData.privacyPolicyUrl} target="_blank">
                        Datenschutzerklärung der Argomed Ärzte AG
                      </HelperTextLink>
                      .
                    </Typography>
                  </FormHelperText>
                </FormControl>
              </WideFormControlContainer>
              <WideFormControlContainer style={{ display: 'flex', justifyContent: 'right' }}>
                <Button type="submit" disabled={registerMutation.isSuccess} loading={registerMutation.isPending}>
                  Speichern und weiter
                </Button>
              </WideFormControlContainer>
            </FormGrid>
          </CardContent>
        </Card>
      </form>
    </ContentSection>
  );
}

const HelperTextLink = styled(Link)`
  display: inline;
  text-decoration: underline;
`;
