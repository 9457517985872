/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { BodyType, ErrorType } from '../../axios';
import { request } from '../../axios';
import type { FileDetailsDto, SaveFileBody } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const saveFile = (
  saveFileBody: BodyType<SaveFileBody>,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  const formData = new FormData();
  formData.append('file', saveFileBody.file);

  return request<FileDetailsDto>(
    {
      url: `/patient-api/registration-files`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      signal,
    },
    options,
  );
};

export const getSaveFileMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveFile>>,
    TError,
    { data: BodyType<SaveFileBody> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof saveFile>>, TError, { data: BodyType<SaveFileBody> }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveFile>>, { data: BodyType<SaveFileBody> }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return saveFile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveFileMutationResult = NonNullable<Awaited<ReturnType<typeof saveFile>>>;
export type SaveFileMutationBody = BodyType<SaveFileBody>;
export type SaveFileMutationError = ErrorType<unknown>;

export const useSaveFile = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveFile>>,
    TError,
    { data: BodyType<SaveFileBody> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof saveFile>>, TError, { data: BodyType<SaveFileBody> }, TContext> => {
  const mutationOptions = getSaveFileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteFileById = (id: string, options?: SecondParameter<typeof request>) => {
  return request<void>({ url: `/patient-api/registration-files/${id}`, method: 'DELETE' }, options);
};

export const getDeleteFileByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteFileById>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteFileById>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFileById>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return deleteFileById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteFileByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFileById>>>;

export type DeleteFileByIdMutationError = ErrorType<unknown>;

export const useDeleteFileById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteFileById>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteFileById>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteFileByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
