/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { BodyType, ErrorType } from '../../axios';
import { request } from '../../axios';
import type {
  EmailVerificationDto,
  RegisterDto,
  RegistrationResultDto,
  RetryEmailVerificationDto,
  VerifyEmail200,
  VerifyEmailDto,
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const retryEmailVerification = (
  registrationId: string,
  retryEmailVerificationDto: BodyType<RetryEmailVerificationDto>,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>(
    {
      url: `/patient-api/registrations/${registrationId}/email-verification`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: retryEmailVerificationDto,
    },
    options,
  );
};

export const getRetryEmailVerificationMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retryEmailVerification>>,
    TError,
    { registrationId: string; data: BodyType<RetryEmailVerificationDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof retryEmailVerification>>,
  TError,
  { registrationId: string; data: BodyType<RetryEmailVerificationDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof retryEmailVerification>>,
    { registrationId: string; data: BodyType<RetryEmailVerificationDto> }
  > = (props) => {
    const { registrationId, data } = props ?? {};

    return retryEmailVerification(registrationId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RetryEmailVerificationMutationResult = NonNullable<Awaited<ReturnType<typeof retryEmailVerification>>>;
export type RetryEmailVerificationMutationBody = BodyType<RetryEmailVerificationDto>;
export type RetryEmailVerificationMutationError = ErrorType<unknown>;

export const useRetryEmailVerification = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof retryEmailVerification>>,
    TError,
    { registrationId: string; data: BodyType<RetryEmailVerificationDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof retryEmailVerification>>,
  TError,
  { registrationId: string; data: BodyType<RetryEmailVerificationDto> },
  TContext
> => {
  const mutationOptions = getRetryEmailVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const registerPatient = (
  registerDto: BodyType<RegisterDto>,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<RegistrationResultDto>(
    {
      url: `/patient-api/registrations`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registerDto,
      signal,
    },
    options,
  );
};

export const getRegisterPatientMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerPatient>>,
    TError,
    { data: BodyType<RegisterDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerPatient>>,
  TError,
  { data: BodyType<RegisterDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerPatient>>, { data: BodyType<RegisterDto> }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return registerPatient(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterPatientMutationResult = NonNullable<Awaited<ReturnType<typeof registerPatient>>>;
export type RegisterPatientMutationBody = BodyType<RegisterDto>;
export type RegisterPatientMutationError = ErrorType<unknown>;

export const useRegisterPatient = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerPatient>>,
    TError,
    { data: BodyType<RegisterDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof registerPatient>>,
  TError,
  { data: BodyType<RegisterDto> },
  TContext
> => {
  const mutationOptions = getRegisterPatientMutationOptions(options);

  return useMutation(mutationOptions);
};
export const verifyEmail = (
  registrationId: string,
  verifyEmailDto: BodyType<VerifyEmailDto>,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<VerifyEmail200>(
    {
      url: `/patient-api/registrations/${registrationId}/email-verification/verify`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: verifyEmailDto,
      signal,
    },
    options,
  );
};

export const getVerifyEmailMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyEmail>>,
    TError,
    { registrationId: string; data: BodyType<VerifyEmailDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof verifyEmail>>,
  TError,
  { registrationId: string; data: BodyType<VerifyEmailDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verifyEmail>>,
    { registrationId: string; data: BodyType<VerifyEmailDto> }
  > = (props) => {
    const { registrationId, data } = props ?? {};

    return verifyEmail(registrationId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyEmailMutationResult = NonNullable<Awaited<ReturnType<typeof verifyEmail>>>;
export type VerifyEmailMutationBody = BodyType<VerifyEmailDto>;
export type VerifyEmailMutationError = ErrorType<unknown>;

export const useVerifyEmail = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyEmail>>,
    TError,
    { registrationId: string; data: BodyType<VerifyEmailDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof verifyEmail>>,
  TError,
  { registrationId: string; data: BodyType<VerifyEmailDto> },
  TContext
> => {
  const mutationOptions = getVerifyEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getLatestEmailVerification = (
  registrationId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<EmailVerificationDto>(
    { url: `/patient-api/registrations/${registrationId}/email-verification/latest`, method: 'GET', signal },
    options,
  );
};

export const getGetLatestEmailVerificationQueryKey = (registrationId: string) => {
  return [`/patient-api/registrations/${registrationId}/email-verification/latest`] as const;
};

export const getGetLatestEmailVerificationQueryOptions = <
  TData = Awaited<ReturnType<typeof getLatestEmailVerification>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLatestEmailVerification>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLatestEmailVerificationQueryKey(registrationId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLatestEmailVerification>>> = ({ signal }) =>
    getLatestEmailVerification(registrationId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!registrationId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLatestEmailVerification>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetLatestEmailVerificationQueryResult = NonNullable<Awaited<ReturnType<typeof getLatestEmailVerification>>>;
export type GetLatestEmailVerificationQueryError = ErrorType<unknown>;

export function useGetLatestEmailVerification<
  TData = Awaited<ReturnType<typeof getLatestEmailVerification>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLatestEmailVerification>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getLatestEmailVerification>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetLatestEmailVerification<
  TData = Awaited<ReturnType<typeof getLatestEmailVerification>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLatestEmailVerification>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getLatestEmailVerification>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetLatestEmailVerification<
  TData = Awaited<ReturnType<typeof getLatestEmailVerification>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLatestEmailVerification>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetLatestEmailVerification<
  TData = Awaited<ReturnType<typeof getLatestEmailVerification>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLatestEmailVerification>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetLatestEmailVerificationQueryOptions(registrationId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
